import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import PropTypes from 'prop-types'

import {
  Section,
  Hero,
  Container,
  Columns,
  Heading
} from 'react-bulma-components'

import Layout from '../../../components/layouts/index'
import SEO from '../../../components/seo'

import '../../styles/index.scss'
import './styles.scss'

class EyeTwentyPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        className="is-paddingless"
        bodyInContainer
      >
        <SEO
          title="EyeTwenty - macOS app to reduce the eyestrain"
          url={this.props.location.href}
        />
        <Section className="is-paddingless">
          <Hero>
            <Hero.Body>
              <Container className="has-text-centered" style={{ top: '-2em' }}>
                <Columns
                  breakpoint="tablet"
                  className="is-centered is-vcentered"
                >
                  <Columns.Column
                    tablet={{
                      size: 'half'
                    }}
                    desktop={{
                      size: 'half'
                    }}
                    className="is-half is-narrow"
                  >
                    <Image
                      className="logoShadowed"
                      fixed={
                        this.props.data.eyeTwentyLogo.childImageSharp.fixed
                      }
                    />
                    <Heading className="is-marginless">EyeTwenty</Heading>
                    <Heading
                      subtitle
                      size={5}
                      renderAs="h2"
                      style={{ marginTop: '.5em' }}
                    >
                      Timer that reminds you to have a break. <br />
                      Get rid of the eyestrain with help of EyeTwenty.
                    </Heading>
                    <br />
                    <a
                      href="https://geo.itunes.apple.com/us/app/eyetwenty/id1225706665?mt=12"
                      style={{
                        display: 'inline-block',
                        overflow: 'hidden',
                        background:
                          'url(//linkmaker.itunes.apple.com/assets/shared/badges/en-us/macappstore-lrg.svg) no-repeat',
                        width: 165,
                        height: 40,
                        backgroundSize: 'contain'
                      }}
                    />
                  </Columns.Column>
                </Columns>
              </Container>
            </Hero.Body>
          </Hero>
        </Section>
      </Layout>
    )
  }
}

EyeTwentyPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
}

export default EyeTwentyPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    eyeTwentyLogo: file(absolutePath: { regex: "/eye-twenty.png/" }) {
      childImageSharp {
        fixed(width: 256, height: 256) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
